.logo {
  padding: 1rem;
  font-size: 1.3rem;
  font-family: 'Rajdhani', sans-serif;

  @import url('https://fonts.googleapis.com/css?family=Rajdhani&display=swap');

  .message {
    font-size: 0.7em;
    opacity: 0.4;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  .app-name {
    font-size: 1.3em;
    font-weight: bold;
  }
  .company-name {
    opacity: 0.5;

    span:nth-child(2) {
      font-weight: bold;
    }
  }
}
